<template>
  <article class="new-question">
    <div class="site-wrapper rythm-v-l">
      <header>
        <h1>Nouvelle question</h1>
      </header>

      <section class="section--new-question rythm-v">
        <header>
          <h2>Étape 3 – Documents</h2>
        </header>

        <div class="card shadow rythm-v">
          <section class="rythm-v">
            <header>
              <h3>Documents</h3>
            </header>

            <p>
              <strong>Avez-vous des documents à joindre à votre question&nbsp;?</strong>
              <br />(diganostics, examens complémentaires, traitements, …)
            </p>

            <ul v-if="documents.length">
              <li></li>
            </ul>

            <p v-if="!documents.length">
              <em>Aucun document n'est attaché à cette question</em>
            </p>

            <form>
              <file-pond
                name="documents"
                ref="pond"
                instant-upload="true"
                chunk-uploads="true"
                max-files="10"
                max-parallel-uploads="4"
                allow-multiple="true"
                allow-revert="true"
                allow-replace="false"
                ignore-files="['.DS_Store', 'thumbs.db', 'desktop.ini']"
                label-idle="Cliquer ou déposer vos fichiers ici pour les ajouter."
                label-file-loading="Préparation…"
                label-file-processing="Envoi du fichier…"
                label-file-processing-complete="Fichier envoyé"
                label-file-processing-aborted="Envoi annulé"
                label-file-remove-error="Erreur lors de la suppression"
                label-tap-to-retry="Cliquer pour recommencer"
                label-button-remove-item="Supprimer"
                label-button-abort-item-load="Abort Load"
                label-button-retry-item-load="Recommencer"
                label-button-abort-item-processing="Abort Process"
                label-button-undo-item-processing="Undo"
                label-button-retry-item-processing="Recommencer"
                label-button-process-item="Envoyer"
                :label-tap-to-cancel="labelTapToCancel"
                :label-tap-to-undo="labelTapToUndo"
                :label-file-processing-error="labelFileProcessingError"
                :files="documents"
                :server="pondOptions.server"
                @init="handleFilePondInit"
                @addfile="handleFilePond_addfile"
                @processfilestart="handleFilePond_processfilestart"
                @processfileprogress="handleFilePond_processfileprogress"
                @processfilerevert="handleFilePond_processfilerevert"
                @processfileundo="handleFilePond_processfileundo"
                @removefile="handleFilePond_removefile"
                @updatefiles="handleFilePond_updatefiles"
                @processfiles="handleFilePond_processfiles"
              />
            </form>
          </section>

          <section>
            <div class="input-group--inline-controls">
              <button class="button--primary" :disabled="nextButtonDisabled" @click.prevent="questionComplete()">
                <span class="caption">Terminer</span>
                <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683418 6.90237 1.31658 7.29289 1.70711L12.5858 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </section>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "src/scss/question.scss";
@import "~@/scss/06-components/components.filepond";
</style>

<script>
// Axios
import { HTTP } from "../http-common.js";

// EventBus
import { EventBus } from "../event-bus.js";

import buttonResetQuestion from "@/components/buttonResetQuestion";

// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilepondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";

// TUS chunk upload
import * as tus from "tus-js-client";

// You can also set Filepond use the setOptions in place of the component's atrributes
// setOptions({
// 	allowMultiple: true,
// 	allowRevert: true,
// 	maxFileSize: "1024MB",
// 	maxParallelUploads: "10",
// 	ignoredFiles: [".DS_Store", "thumbs.db", "desktop.ini"],
// 	labelMaxFileSizeExceeded: "Le fichier est trop lourd"
// });

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilepondPluginFileValidateSize, FilePondPluginFileMetadata);

export default {
  name: "QuestionEditDocuments",

  components: {
    buttonResetQuestion,
    FilePond,
  },

  props: ["questionID"],

  data: function() {
    return {
      nextButtonDisabled: false,
      isDraft: false,
      documents: [],

      labelFileProcessingError: "Erreur pendant le téléchargement",
      labelTapToUndo: "Cliquer pour supprimer",
      labelTapToCancel: "Cliquer pour annuler",

      pondOptions: {
        // Filepond option, replaced with custom process (tus)
        // but specified just in case…
        chunkUploads: true,

        server: {
          // Filepond still handles some requests (such as DELETE)
          url: `${HTTP.defaults.baseURL}/files`,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${this.$store.state.userToken}`,
          },

          // Le processus d'upload est passé à tus-js-client qui gère le chunk upload
          process: (fieldName, file, metas, load, error, progress, abort, transfer, options) => {
            var upload = new tus.Upload(file, {
              endpoint: `${HTTP.defaults.baseURL}/files`,
              chunkUploads: true,
              chunkSize: 5000000,
              retryDelays: [1000],
              chunkRetryDelays: [500],
              uploadDataDuringCreation: true,
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${this.$store.state.userToken}`,
              },
              withCredentials: false,

              // NOTE: the PATCH method may not be supported by the remote server.
              // use POST instead with an extra `X-HTTP-Method-Override: PATCH` header.
              // The ankitpokhrel/tus-php library will handle the alternative POST requests by itself.
              overridePatchMethod: true,

              // Remove file fingerprints from localStorage
              // NOTE: If not working, we disable resumable uploads below…
              removeFingerprintOnSuccess: true,
              // Disabling resumable uploads: can't pause and resume uploads
              // but allow to upload the same filename again
              resume: false,
              canStoreURLs: false,

              // Additionnal file data
              metadata: {
                filename: file.name,
                filetype: file.type,
                // this is a question attachment
                objectid: this.questionID,
                uploadtype: "documents",
              },

              onError: (err) => {
                this.nextButtonDisabled = false;

                // We try to get custom error messages from the server response
                let responseText = (upload && upload._xhr && upload._xhr.responseText) || null;
                let errorMsg = "Erreur pendant le téléchargement";
                if (responseText) errorMsg = JSON.parse(responseText).payload.error;

                console.warn("onError", err, responseText, errorMsg);

                // EventBus.$emit(
                //   "trackEvent",
                //   "Documents Question",
                //   `Erreur envoi pour question ${this.questionID} par userID ${this.user.id}`
                // );

                if (errorMsg) this.labelFileProcessingError = errorMsg;
                return error(errorMsg);
              },

              onProgress: (bytesUploaded, bytesTotal) => {
                var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                progress(true, bytesUploaded, bytesTotal);
                // console.log(bytesUploaded, bytesTotal, percentage + "%");
              },

              onSuccess: () => {
                console.info("upload", upload);
                load(upload.url.split("/").pop());
              },
            });

            // Start the upload
            upload.start();
          },
        },
      },
    };
  },

  computed: {
    // Current user data
    user: function() {
      return this.$store.state.userData;
    },
  },

  methods: {
    // Reset local draft and display the question
    questionComplete: function() {
      this.$store.dispatch("RESET_QUESTION_DRAFT").then(() => {
        this.$router.push("/dashboard");

        this.$toasted.global.appSuccess({
          message: "<strong>Votre question est attribuée à l’un de nos experts.</strong><br>Vous serez notifié par e-mail quand un expert aura répondu.",
        });
      });
    },

    // Filepond intance
    handleFilePondInit: function() {
      console.log("FilePond has initialized");
      // FilePond instance methods are available on `this.$refs.pond`
    },

    // New file added to queue
    handleFilePond_addfile: function(e) {
      this.nextButtonDisabled = true;
      // console.log("FilePond handleFilePond_addfile", e);
    },

    // Remove the file from queue (no delete)
    handleFilePond_removefile: function(e) {
      this.nextButtonDisabled = false;
      // console.log("FilePond handleFilePond_removefile", e);
    },

    // Starting processing file
    handleFilePond_processfilestart: function(e) {
      // Too late to cancel!
      this.labelTapToCancel = "";
      // console.log("FilePond handleFilePond_processfilestart", e);
    },

    // File progress
    handleFilePond_processfileprogress: function(e) {
      this.nextButtonDisabled = true;
      // console.log("FilePond handleFilePond_processfileprogress", e);
    },

    // Cancel?
    handleFilePond_processfilecancel: function(e) {
      // console.log("FilePond handleFilePond_processfilecancel", e);
    },

    // Process file undo
    handleFilePond_processfileundo: function(e) {
      // console.log("FilePond handleFilePond_processfileundo", e);
    },

    // Abort (before upload has started)
    // @processfileabort="handleFilePond_processfileabort"
    handleFilePond_processfileabort: function(e) {
      // console.log("FilePond handleFilePond_processfileabort", e);
    },

    // Delete uploaded file
    handleFilePond_processfilerevert: function(e) {
      // console.log("FilePond handleFilePond_processfilerevert", e);
    },

    // A file has been added or removed
    handleFilePond_updatefiles: function(e) {
      // console.log("FilePond handleFilePond_updatefiles", e);
    },

    // All files uploaded
    handleFilePond_processfiles: function(e) {
      this.nextButtonDisabled = false;
      // console.log("FilePond handleFilePond_processfiles", e);
    },

    // Warn user when leaving the page
    quitWarning: function(e) {
      if (!this.confirmLeave()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },

    confirmLeave() {
      return window.confirm("Cette page demande de confirmer sa fermeture ; des données saisies pourraient ne pas être enregistrées !");
    },
  },

  mounted() {
    // Warn user when leaving the page
    // window.addEventListener("beforeunload", this.quitWarning);

    // NOTE: Now we NEED a questionID, go back if no data
    if (!parseInt(this.questionID)) return this.$router.push(`/edit-question-details/0`);
  },

  // Navigation gards
  beforeRouteLeave(to, from, next) {
    // Pass questionID if the user goes back (to question details)
    if (to.path === "/edit-question-details/0" && this.questionID) {
      return window.location.replace(`/edit-question-details/${this.questionID}`);
    }
    // TODO: confirm leave?
    next();
  },
};
</script>
